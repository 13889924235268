import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Rightcontainer from "../Components/Rightcontainer";
import Accordion from '@mui/material/Accordion';
// import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import Header from "../Components/Header";
import "../css/terms.css";
//import { Interweave } from "interweave";
const All_policy = () => {
    const [data, setData] = useState()
    const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
    const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
    const nodeMode = process.env.NODE_ENV;

    if (nodeMode === "development") {
        var baseUrl = beckendLocalApiUrl;
    } else {
        var baseUrl = beckendLiveApiUrl
    }

    return (
        <div>
            <div className="leftContainer" style={{ minHeight: '100vh', height: '100%' }}>
                <div   className="mt-5 py-4 px-3">


                    <Accordion style={{marginTop:"10px"}}>
                        <AccordionSummary
                            expandIcon={<i class="fa-solid fa-chevron-down"></i>}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <span style={{ color: "#3A427D", fontSize:"20px" }}> All Policy</span>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ul style={{ fontSize: "16px" }} className=" list-unstyled ml-2 mt-2 ">
                                <div className="row">
                                    <Link   to="/term-condition"><li style={{ color: "#959595" }} className="col giveHover">Terms & Conditions</li></Link>
                                    <Link   to="/PrivacyPolicy"><li style={{ color: "#959595" }} className="col giveHover">Privacy policy</li></Link>
                                </div>
                                <div className="row">
                                    <Link to="/refund-policy"><li style={{ color: "#959595" }} className="col giveHover">Refund/Cancellatioin Policy</li></Link>

                                    <Link to="/contact-us"> <li style={{ color: "#959595" }} className="col giveHover">Contact Us</li> </Link>
                                </div >
                                  <div className="row">
                                <Link to="/responsible-gaming"> <li className="col giveHover" style={{ color: "#959595" }}>Responsible Gaming </li></Link>
                                 <Link to="About"> <li style={{ color: "#959595" }} className="col giveHover">About Us</li> </Link>
                                 </div>
                            </ul>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
            <div className="rightContainer">
                <Rightcontainer />
            </div>
        </div>
    );
};
export default All_policy;