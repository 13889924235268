import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Link,
  //    NavLink, useHistory, useLocation
} from "react-router-dom";
//import Swal from "sweetalert2";
import "../css/landing.css";
import { Collapse } from "react-bootstrap";
//import Rightcontainer from "../Components/Rightcontainer";
import Downloadbutton from "../Components/Downloadbutton";
import Header from "../Components/Header";

export default function Landing() {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  var baseUrl;
  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  const [open, setOpen] = useState(false);
  const [userAllData, setUserAllData] = useState();
  const [WebSitesettings, setWebsiteSettings] = useState("");
  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    return setWebsiteSettings(data);
  };

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        const passworduser= res.data.Password;
        setUserAllData(res.data);
        // if(passworduser.length > 13){
        //   localStorage.removeItem("token");
        // }
      })
      .catch((e) => {
        if (e.response?.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          //window.location.href = "/login";
          //window.location.reload()
          //history.push("/login")
        }
      });
  };

  useEffect(() => {
    let access_token = localStorage.getItem("token");
    access_token = localStorage.getItem("token");
    if (!access_token) {
      //window.location.reload()
    }
    role();
    fetchData();
  }, []);

  return (
    <>


      <div className="leftContainer">
        <div className="main-area" style={{ paddingTop: "65px" }}>
          <div className="header_top_message">
            <span>Commission: 5% ◉ Referral: 3% For All Games No TDS,No GST</span>
          </div>
         
          <div style={{ backgroundColor: "white" }} className="header_top_message">
            <span style={{ color: "green" }}>
 
 


            Deposit और Withdrawal में कोई भी समस्या आये तो तुरंत संपर्क करे 9660354877 (only whatsupp) सपोर्ट ...धन्यवाद

 <br/>
 </span>

            
            
         {/*एप्लिकेशन अभी मेंटेनेंस मोड पर है, कृपया इंतजार करें....धन्यवाद
           */}
         </div>

          {/* <div class="alert alert-warning mb-0" id="noteAlert" role="alert">
            {" "}
            <picture>
              <img
                height="14px"
                width="20px"
                src="/Images/LandingPage_img/notice.png"
                alt=""
              />
            </picture>{" "}
            <b>NOTICE ! </b> <a
                  href={
                    WebSitesettings
                      ? "mailto:" + WebSitesettings.CompanyEmail
                      : ""
                  }
                >
                  {WebSitesettings
                    ? WebSitesettings.CompanyEmail
                    : ""}
                </a></div>*/}
          {/*  <div className="alert alert-primary" role="alert">
प्लेयर्स कृपया ध्यान दे आप सभी को KingAdda.Com की तरफ से दीपावली की हार्दिक शुभ कामनाये आज आप सभी से कोई कमीशन चार्ज नहीं किया जायगा ऑफर सिर्फ आज रात 12 बजे तक रहेगा धन्यवाद
</div>*/}

 

          <section className="games-section p-3">
       <div style={{ height: "130px" }} id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img
                    style={{ width: "450px", height: "130px" }}
                    src={"https://ik.imagekit.io/qd1zdw8qs/slider_11.png?updatedAt=1714462014298"
                    }
                    alt="First slide"
                  />                  </div>
                <div class="carousel-item">
                  <img
                    width="100%"
                    style={{ height: "130px" }}
src={"https://ik.imagekit.io/wnm4oiopu/Screenshot%202024-04-30%20at%202.53.51%20AM.png?updatedAt=1714425885409"}
                    alt="Second slide" />
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center games-section-title">

            </div>
            <div className="games-section-headline mt-2 mb-1">

              <div className="games-window">
                <Link
                  className="gameCard-container"
                  to={`/Homepage/Ludo%20Classics%20Lite`}
                >
                  <span className="d-none blink text-primary d-block text-right">
                    ◉ LIVE Min.50 Max.50k
                  </span>
                  <picture className="gameCard-image rounded-lg">
                    <img

                      width="100%"
                    //   src={
                    //     process.env.PUBLIC_URL +
                    //     "/Images/LandingPage_img/Ludo_classic.png"
                    //   }
                    src="https://ik.imagekit.io/wnm4oiopu/dm6rPjl.png?updatedAt=1714425974021"
                      alt=""
                    />
                  </picture>
                  {/* <div className="gameCard-title">
                    <span className="d-none text-dark d-block text-right">
                      ◉ LUDO CLASSIC
                    </span>
                  </div> */}
                  {/* <div className="goverlay">
                    <div className="text">LIVE</div>
                  </div> */}
                </Link>
                <Link
                  className="gameCard-container"
                >
                  <span className="d-none blink text-primary d-block text-right">
                    COMING SOON
                  </span>
                  <picture className="gameCard-image rounded-lg">
                    <img
                      width="100%"
                      src="https://ik.imagekit.io/wnm4oiopu/P6qiphp.png?updatedAt=1714425973751
"
                    //   src={
                    //     process.env.PUBLIC_URL +
                    //     "/Images/LandingPage_img/Ludo_popular.png"
                    //   }
                      alt=""
                    />
                  </picture>
                  {/* <div className="gameCard-title">
                    <span className="d-none text-dark d-block text-right">
                      ◉  LUDO POPULAR
                    </span>
                  </div> */}
                  {/* <div className="goverlay">
                    <div className="text">WhatsApp Support</div>
                  </div> */}
                </Link>
                <Link
                  className="gameCard-container"
                // to={`/Homepage/Ludo%201%20Goti`}
                >
                  <span className="d-none blink text-primary d-block text-right">
                    COMING SOON
                  </span>
                  <picture className="gameCard-image rounded-lg">
                    <img
                      width="100%"
                      src="https://ik.imagekit.io/wnm4oiopu/4Yje4O8.png?updatedAt=1714425974338"
                    //   src={
                    //     process.env.PUBLIC_URL +
                    //     "/Images/LandingPage_img/snack_leader.png"
                    //   }
                    />
                  </picture>


                </Link>
               

                
                  
                    
                
                
                 <div className="gameCard-container">
              <a
                className="cxy flex-column"
                href={
                  "https://wa.me/+918239680029?text=hello%20admin%20i%20need%20help"
                }
              >
               <span className="d-none blink text-primary d-block text-right">
                 24*7 Support </span>
                 
                <img
                      
                      width="100%"
                      src="https://ik.imagekit.io/wnm4oiopu/emsL3Iy.png?updatedAt=1714425973636
"
                    //   src={
                    //     process.env.PUBLIC_URL +
                    //     "/Images/LandingPage_img/vt_Support.png"
                    //   }
                      alt=""
                    />
                   
              </a>
            </div>
                {/* <Link className="gameCard-container" to={`/Homepage/Ludo%20Popular`}> */}
                {/*
                <Link className="gameCard-container" to={``}>
                  <span className="d-none blink text-danger d-block text-right">
                    ◉ COMING SOON
                  </span>
                  <picture className="gameCard-image">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/LandingPage_img/secondLudo.webp"
                      }
                      alt=""
                    />
                  </picture>
                  <div className="gameCard-title">
                    <span className="d-none text-dark  d-block text-right">
                      ◉ POPULAR LUDO
                    </span>
                  </div>
                  <div className="goverlay">
                    <div className="text">Comming Soon</div>
                  </div>
                </Link>
*/}
                {/* <Link className="gameCard-container" to={`/Homepage/Ludo%201%20Goti`}> */}
                {/* <Link className="gameCard-container" to={``}>
                                        <span className="d-none blink text-danger d-block text-right">◉ LIVE</span>
                                        <picture className="gameCard-image">
                                            <img width="100%" src={process.env.PUBLIC_URL + '/Images/LandingPage_img/thirdLudo.webp'} alt="" />
                                        </picture>
                                       
                    <div className="gameCard-title">
                                            <span className="d-none blink text-success d-block text-right">◉ Ludo no cut </span>

                                        </div>
                                        <div className="goverlay">
                                            <div className="text">Comming Soon</div>
                                        </div>
                                    </Link> */}

                {/* <Link className="gameCard-container" to={`/Homepage/Ludo%20Ulta`} > */}
                {/* <Link className="gameCard-container" to={``} >
                                        <span className="d-none blink text-danger d-block text-right">◉ LIVE</span>
                                        <picture className="gameCard-image">
                                            <img width="100%" src={process.env.PUBLIC_URL + '/Images/LandingPage_img/fourthLudo.webp'} alt="" />

                                        </picture>
                                        <div className="gameCard-title">
                                            <span className="d-none blink text-success d-block text-right">◉ Ludo Ulta </span>

                                        </div>

                                        <div className="goverlay">
                                            <div className="text">Comming Soon</div>
                                        </div>

                                    </Link> */}
              </div>











            </div>
            <hr></hr>




          </section>



          <div className="collapseCard-container">

            <div className="collapseCard">
              <a
                href="#"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <div
                  className="collapseCard-body"
                  style={{
                    height: "64px",
                    opacity: 1,
                    transition: "height 0.3s ease 0s, opacity 0.3s ease 0s",
                  }}
                >
                  <div className="collapseCard-text text-primary">
                    How to win money ?
                  </div>
                </div>
              </a>
              <div
                className="collapseCard-header"
                style={{ left: "22px", transition: "left 0.3s ease 0s" }}
              >
                <picture>
                  <img
                    height="10px"
                    width="14px"
                    src="https://khelbro.com/images/global-ytPlayIcon.png"
                    alt=""
                  />
                </picture>
                <div className="collapseCard-title ml-1 mt-1">Video Help</div>
              </div>
            </div>
          </div>

          <div style={{ paddingBottom: "60px" }} className="w1">
            <a href="https://wa.me/+918239680029?text=hello%20admin%20i%20need%20help">
              <button className="w2">
                <span className="w3">किसी भी समस्या के लिए व्हाट्सप्प पर कांटेक्ट करे।</span>
              </button>
            </a>
          </div>

          <section className="footer">
            <div className="footer-divider" />

          </section>
          <div className="downloadButton">
            <Downloadbutton />
          </div>
        </div>
      </div>
      {/* // <div className='rightContainer'>
            //     <Rightcontainer/>
            // </div> */}
    </>
  );
}
