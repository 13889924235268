import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
import Rightcontainer from '../Components/Rightcontainer'
import '../css/layout.css'
import '../css/login.css'
import loginss from "./ss.png"
import DeviceDetector from "device-detector-js";
import { Address4 } from "ip-address";
export default function Login() {

    const history = useHistory()
    const [Phone, setPhone] = useState();
    const [twofactor_code, settwofactor_code] = useState();
    const [otp, setOtp] = useState(false)
    const [secretCode, setSecretCode] = useState();
    const [Password, setPassword] = useState();
    const [ipAddress, setIpAddress] = useState("");
    const [deviceName, setDeviceName] = useState("");
    const [referral, setReferral] = useState(useLocation().pathname.split("/")[2])

    
    useEffect(() => {
    // Get IP address
    axios.get('https://api.ipify.org?format=json')
      .then(response => {
        const ip = response.data.ip;
        const address = new Address4(ip);
        setIpAddress(address.address);
        

      })
      .catch(error => {
        console.error('Error fetching IP address:', error);
      });

    const deviceDetector = new DeviceDetector();
    const userAgent = window.navigator.userAgent;
    const device = deviceDetector.parse(userAgent);
    setDeviceName(device.device.model || "Unknown Device");

  }, []);
    
    
    
    const handleClick = async (e) => {
        e.preventDefault();

        // if (!Phone || !Password) {
        //     Swal.fire({
        //         icon: 'error',
        //         title: 'Oops...',
        //         text: 'Please enter your phone and Password',

        //     });

        // }
        if (!Phone) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please enter your phone',

            });

        }
        else if (Phone.length != 10) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please  enter currect phone number',

            });
        }
        else {
            await axios.post(`https://fuapivxi.vtludoclub.com/login`, {
                Phone, referral,Password,ipAddress,deviceName,
            }).then((respone) => {
                if (respone.data.status == 101) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: respone.data.msg,
                    })
                }
                else if (respone.data.status == 200) {
                    setOtp(true)
                    console.log(respone.data);
                    setSecretCode(respone.data.secret);
                }
            })
                .catch((e) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong',
                        // width: '20%',
                        // height:'20%',
                    })
                })
        }
    }


    const varifyOtp = async (e) => {
        e.preventDefault();
        console.log('verify otp sumbut req');
        if (!Phone) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please enter your phone number',

            })
        } else {
            await axios.post(`https://fuapivxi.vtludoclub.com/login/finish`, {
                Phone,
                twofactor_code,
                referral,
                secretCode
            }).then((respone) => {

                if (respone.data.status == 101) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: respone.data.msg,
                    })
                }
                else if (respone.data.status == 200) {

                    const token = respone.data.token
                    localStorage.setItem("token", token)
                    window.location.reload(true)
                    setTimeout(function () {
                        history.push("/Games")
                    }, 1000);

                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                    })
                }
            })
                .catch((e) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                    })
                })
        }
    }

    const setError = () => {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Invalid Number',
            confirmation: true,
        })
    }
    return (
        <>
            <div className='leftContainer' style={{ minHeight: '100vh', backgroundColor: "white" }}>
                <div className="main-area bg-dark">
                    <div style={{ overflowY: 'hidden' }}>
                        <div className="splash-overlay" />
                        <div className="splash-screen animate__bounce infinite ">
                            <figure><img width="100%" src={loginss} alt="" /></figure>
                        </div>
                        <div className="position-absolute center-xy   mx-5 " style={{ top: '30%', width: "84%", height: "500px", zIndex: 9, backgroundColor: "#E6E6E6", borderRadius: "20px" }}>
                            <div style={{ color: "#050A30",padding:"8px 0", borderBottom: "2px solid #050A30", backgroundColor: "#f9f9f9", width: "100%", borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }} className="d-flex center-xy  font-15 mb-4">
                           
                            Quick Login</div>
                            <div className="bg-whtie  cxy flex-column" style={{
                                marginTop: "30px", width: '85%', height: "auto", backgroundColor: "#ffffff", borderRadius: "5px", border: "2px solid black"
                            }}>
                                <div className="input-group mb-2 bg-white" style={{ transition: 'top 0.5s ease 0s', top: '5px' }}>
                                    <div className="input-group-prepend">
                                        <div className="input-group-text bg-white" style={{ fontSize: "16px", padding: "7px 5px 7px 20px", width: '60px', border: '0px solid #d8d6de' }}>+91</div>
                                    </div>
                                    <input className="form-control bg-white" name="mobile" type="tel" placeholder="Mobile number"
                                        // onChange={(e) => setPhone(e.target.value)}
                                        onChange={(e) => {
                                            setPhone(e.target.value);
                                            if (e.target.value.length > 10) {
                                                setError(true)
                                            }
                                        }}
                                        style={{ transition: 'all 3s ease-out 0s', letterSpacing: "1px", border: "none", fontSize: "1px" }}
                                    />
                                    {/* <div className="invalid-feedback">Enter a valid mobile number</div> */}
                                </div>

                            </div>
                            
                           {/* <div
                className="bg-whtie  cxy flex-column"
                style={{
                  marginTop: "30px",
                  width: "85%",
                  height: "auto",
                  backgroundColor: "#ffffff",
                  borderRadius: "5px",
                  border: "2px solid black",
                }}
              >
                <div
                  className="input-group mb-2 bg-white"
                  style={{ transition: "top 0.5s ease 0s", top: "5px" }}
                >
                 
                  <input
                    className="form-control bg-white"
                    name="password"
                    type="text"
                    placeholder="Enter Your Password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    style={{
                      transition: "all 3s ease-out 0s",
                      letterSpacing: "1px",
                      border: "none",
                      fontSize: "1px",
                    }}
                  />
                </div>
                </div>*/}
                            
                            {otp && <div className="bg-white  cxy flex-column" style={{
                                width: '85%', height: 'auto', backgroundColor: "white", borderRadius: '5px', marginTop: "10px", border: "2px solid black"
                            }}>

                                <div className="input-group mb-2" style={{ transition: 'top 0.5s ease 0s', top: '5px' }}>
                                    <div className="input-group-prepend">
                                        <div className="input-group-text" style={{ width: '60px', backgroundColor: '#ffffff', padding: "7px 5px 7px 20px", border: '0px solid #d8d6de' }}>OTP</div>
                                    </div>
                                    <input className="form-control" name="password" type="tel" placeholder="Enter OTP"
                                        onChange={(e) => settwofactor_code(e.target.value)}

                                        style={{ transition: 'all 3s ease-out 0s', letterSpacing: "4px", borderRadius: "4px", border: '0px solid #d8d6de' }} />
                                    {/* <div className="invalid-feedback">Enter a valid mobile number</div> */}
                                </div>
                            </div>}
                            {!otp && <button   className="Login-button cxy mt-4" onClick={(e) => handleClick(e)} >Continue
                            </button>}
                            {otp && <button className="Login-button cxy mt-4" onClick={(e) => varifyOtp(e)} >Verify
                            </button>}

                            <span style={{ color: "#050A30", fontWeight: "700", marginBottom:"10px" }} className='mt-4 '>Don't have an account? <a href='/register'> Register</a></span>

                        </div>
                        <div className="login-footer">By continuing I agree that Fast battle Pvt. Ltd. may store and process my data in accordance with the <Link to="/term-condition">Terms of Use</Link>, <Link to="/PrivacyPolicy">Privacy Policy</Link> and that I am 18 years or older. I am not playing from
                            Assam, Odisha, Nagaland, Sikkim, Meghalaya, Andhra Pradesh, or Telangana.
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}